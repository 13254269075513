import React from 'react';
import Scroll from '../common/Scroll';
import MiniGoshaComponent     from '../../components/svg/MiniGosha';

const Introduction = () =>

  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
    <MiniGoshaComponent className="welcome-img"></MiniGoshaComponent>
    <h1>Hello!</h1>
      <p>Hello and welcome to <span>Radio Gosha</span> the studio of Seattle-based visual designer and animator <span>James Franzen</span>. I hope my artwork is able to transmit positivity or spark some happiness in your life!
      </p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#one" className="button">
              My latest work
            </a>
          </Scroll>
        </li>
        <li>
            <a href="https://radiogosha.storenvy.com/" className="button primary">
              Visit Shop
            </a>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
