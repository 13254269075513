import React from 'react';
import { Link } from 'gatsby';

import CapabilityList from './CapabilityList';

const Capabilities = () =>
  <section id="two" className="wrapper style3 fade-up dark">
    <div className="inner">
      <h2>What I do</h2>
      <p>
      Over the years I have developed an aesthetic shaped through the use of vector-based digital tools.  I enjoy seeing what can be expressed through minimalism.  Below are just a few areas I enjoy dabbling in the most along with a link to learn more about me.</p>
      <ul className="actions">
        <li>
          <Link className="button" to="/about">
            Learn about me
          </Link>
        </li>
      </ul>
      <br/>
      <div className="features">
        <CapabilityList />
      </div>

      
    </div>
  </section>

export default Capabilities;
