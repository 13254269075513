import React from 'react';

import anything from '../../images/animations/anything_thumbnail.jpg';
import mtc_saga_fc from '../../images/animations/mtc_saga_fc_thumbnail.jpg';
import cyborgs from '../../images/animations/cyborgs_thumbnail.jpg';

import Feature from './Feature';

const Features = () => {
  const FEATURES = [
    {
      href: 'https://youtu.be/ysxLAMwNBy4',
      image: mtc_saga_fc,
      heading: 'MTC Saga',
      description: 'An epic tale set to the MTC series of songs. A collaboration with music producer S3RL.',
      to: {
        href: 'https://youtu.be/zKI2BHvahKA',
        label: 'Watch',
      },
    },
    {
      href: 'https://youtu.be/C8lsY_Q_duw',
      image: anything,
      heading: 'Anything',
      description: 'No matter how hard life can get, as long as we have each other, we can do anything. A collaboration with the music producer, Alaguan.',
      to: {
        href: 'https://youtu.be/C8lsY_Q_duw',
        label: 'Watch',
      },
    },
    {
      href: 'https://youtu.be/4J76SqyCOm8',
      image: cyborgs,
      heading: 'Cyborgs',
      description: 'The story of how far one cyborg will go to retain the memories of a loved one. A collaboration with music producers J-MI & Midi-D.',
      to: {
        href: 'https://youtu.be/4J76SqyCOm8',
        label: 'Watch',
      },
    },
  ];

  const [features] = React.useState(FEATURES);

  return (
    <section id="one" className="wrapper style2 fade-up spotlights">
       {features.map(feature => <Feature key={feature.heading} {...feature} />)}
    </section>
  );
}

export default Features;
