import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={172}
      height={207}
      viewBox="0 0 172 207"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Rectangle 5"}</title>
      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
	<g>
		<g id="Layer3_0_FILL">
			<path d="M38.1,143.8l3.4-9l-8.3,3c-3.7-3.2-8-4.8-13-4.7c-5.6,0-10.3,2-14.3,5.9C2,143,0,147.8,0,153.4c0,5.6,2,10.3,5.9,14.2
				c4,3.9,8.7,5.9,14.3,5.9c5.6,0,10.3-2,14.2-6c3.9-3.9,5.9-8.7,6-14.2C40.5,150,39.7,146.8,38.1,143.8 M25.6,144.7
				c0.5-0.4,1-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5c0,0.6-0.2,1.1-0.5,1.5l-0.1,0c-0.4,0.5-0.9,0.7-1.5,0.6
				c-0.5,0-1-0.2-1.5-0.6c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.6,0.2-1.1,0.5-1.5L25.6,144.7 M25.2,150.4h3.8v10.6h-3.8V150.4 M11.5,143.8
				L11.5,143.8l3.8-0.1v8c0.4-0.5,0.9-0.9,1.5-1.2c0.6-0.3,1.3-0.4,1.9-0.4c0.8-0.1,1.5,0.1,2.2,0.5c0.6,0.3,1,0.9,1.3,1.6
				c0.3,0.8,0.5,1.5,0.5,2.3v6.5h-3.8v-5.4c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.2-0.7-0.5-0.9c-0.4-0.3-0.8-0.4-1.1-0.4
				c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,0.9c-0.1,0.3-0.2,0.7-0.2,1.2v5.4h-3.8V143.8 M150.1,23.1
				C135.5,7.8,116.9,0.1,94.3,0C71.8,0.1,53.2,7.8,38.5,23.1c-4.7,4.8-8.4,10-11.3,15.7c-4.1,8-6.2,16.5-6.4,25.7
				c0,0.5,0,0.9,0.1,1.2h-0.1v43.8h1.2c0.8,2.1,2,3.9,3.6,5.5c3,3.1,6.7,4.6,11,4.5c1.6,0,3.2-0.2,4.7-0.6l2.8,2.9
				c3.6,3.7,7.6,6.9,12,9.9l1.6,1c4.1,2.5,8.5,4.6,13.1,6.2c-6,5.5-12.7,9.7-20.1,12.7h0.1c-2.3,0.9-3.9,2.5-4.9,4.8
				c-1,2.3-1,4.5-0.1,6.8l0.1,0.1c0.9,2.3,2.5,3.9,4.8,4.8h0.1c2.2,0.9,4.5,0.9,6.7,0h0.1c4.3-1.8,8.3-3.8,11.9-5.9
				c-0.7,4.5-1.2,9-1.4,13.5c-0.4,8.2-0.2,16.4,0.8,24.5c0.1,0.9,0.5,1.7,1.2,2.2c0.7,0.6,1.5,0.9,2.3,0.9h10.7c1,0,1.8-0.3,2.5-1
				c0.7-0.6,1.1-1.4,1.1-2.4c0.1-5.5,0.8-11.1,2-16.6h11.8c1,5.4,1.6,11,1.8,16.6c0.1,0.9,0.4,1.7,1.1,2.4c0.7,0.6,1.5,1,2.5,1h10.7
				c0.9,0,1.7-0.3,2.4-0.9c0.7-0.6,1-1.3,1.2-2.2c0.5-4.2,0.8-8,0.8-11.4l1-0.4v4.5h3.5v-5.6c3.7-2.8,4.4-7.2,2.2-13.4l-0.4-0.6
				c-0.3-3.1-1.2-5.8-2.9-8.2l-0.2-0.2l-0.8-0.8c2.5,1.3,5.3,2.6,8.3,3.9h0c2.2,0.9,4.5,0.9,6.7,0h0c2.3-0.9,3.9-2.5,4.9-4.8V163
				c1-2.2,1-4.5,0-6.6c-0.8-2.4-2.5-4.1-4.9-4.9h0c-7.6-3.1-14.3-7.4-20.1-12.8c4.7-1.7,9-3.7,13-6.1l1.6-1.1
				c4.3-2.8,8.3-6.1,12-9.8l2.5-2.6c1.1,0.2,2.3,0.4,3.6,0.4c2.1,0,4.1-0.4,6-1.1c1.9-0.8,3.6-1.9,5-3.4c1.6-1.6,2.8-3.4,3.6-5.5
				h2.1V65.6h-0.1c0.1-9.6-2.1-18.7-6.5-27.1C158.2,32.8,154.5,27.6,150.1,23.1 M31,62.1l11.2-9l16.3,10.9c0.5,0.3,1,0.4,1.5,0.4
				c0.5,0,1.1-0.2,1.6-0.5l15.1-10.9l15.8,11.3c0.5,0.3,1,0.5,1.5,0.5c0.6,0,1.1-0.2,1.6-0.5l15.8-11.3l15.2,10.6
				c0.6,0.3,1.1,0.5,1.5,0.5c0.6,0,1.1-0.1,1.5-0.4l16.3-10.6l12.5,10.5l0.2,1.9H104v18.1H84.8v-18H30.6L31,62.1 M37.6,114.1h-0.8
				c-2.9,0-5.3-1-7.2-3h-0.1c-0.5-0.6-1-1.1-1.4-1.8h6.1C34.8,110.3,35.9,111.9,37.6,114.1 M141,115.7l-1,1c-3.3,3.3-6.9,6.3-10.8,9
				l-1.5,0.9c-4.6,2.9-9.5,5-14.5,6.4c-3.2,0.9-6.5,1.6-9.9,2c-1.8,0.3-3.6,0.4-5.3,0.5l-3.2,0.1l-8.7-0.6c-3.2-0.4-6.4-1-9.6-1.9
				c-5.3-1.5-10.2-3.7-14.8-6.5l-1.5-0.9c-4-2.6-7.6-5.6-10.9-9l-1.5-1.6h0c-1.4-1.4-2.5-2.8-3.5-4l-1.2-1.6h41.8V90.8H104v18.6
				h42.4l-1.9,2.3C143,113.5,141.9,114.8,141,115.7 M158.5,111c-1.8,1.9-4,2.9-6.8,3c1.2-1.5,2.3-3,3.3-4.7h4.8
				C159.5,109.9,159,110.5,158.5,111 M106.5,141.7c1.2-0.2,2.9-0.5,5-1c4.6,4.9,9.9,9,15.9,12.3l-0.5,1l2.4,1.1l-0.3,0.6l-2.5-1.1
				L126,156l2.3,1.1l-0.3,0.6l-2.3-1.1l-0.6,1.1c-6.1-3.3-11.6-7.5-16.5-12.6c2,6.5,3.5,13.3,4.5,20.4h-5.7l-0.1-0.1l0.1-0.9v-0.5
				l0-0.3l0-0.3v-2.1c0.1-0.7-0.1-1.3-0.5-1.8s-0.9-0.8-1.5-0.9c-0.2,0-0.5,0-0.7,0h-7.3c0-1-0.3-1.8-1-2.4l-0.2-0.2v-7.5
				c2.8-0.3,5.3-1.3,7.5-3.1C104.8,144.5,105.8,143.3,106.5,141.7 M97.5,144.8l1.7-2.3l2.6-0.1l-0.5,0.6h0
				C100.1,143.8,98.8,144.4,97.5,144.8 M88.5,142.4l1.4,0.1l1.6,2.2c-1.1-0.3-2.2-0.9-3.2-1.6L88.5,142.4 M84.6,141.9
				c0.1,0.3,0.1,0.6,0,0.9l-1.4,1.8c-0.4,0.6-1,1-1.8,1.2c-0.2,0.1-0.5,0.1-0.9,0h-0.1l-0.8-0.1c-0.6-0.1-1.1-0.4-1.5-0.8
				c-0.8-0.6-1.4-1.4-2-2.3c-0.3-0.5-0.5-0.9-0.5-1.4l0,0l1.8-0.5C80,141.2,82.4,141.6,84.6,141.9 M87,149.5l-1.6-2l1.2-1.4
				c1.9,1.4,4.1,2.2,6.6,2.5v6.7l-0.5,0.1l-1.4-1.3c0.2-0.4,0.3-0.9,0.3-1.4c0-1-0.3-1.8-1-2.4s-1.5-1-2.4-1c0,0,0,0,0,0
				C87.8,149.3,87.4,149.4,87,149.5 M88.1,151.1h0.3c0.4,0.1,0.7,0.2,0.9,0.5c0.3,0.3,0.4,0.6,0.4,1v0.1c0,0.4-0.2,0.8-0.5,1.1
				s-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.2-1.2-0.5s-0.5-0.7-0.5-1.1c0-0.4,0.2-0.8,0.5-1.1C87.2,151.3,87.6,151.1,88.1,151.1 M85.1,154.5
				l0.6,0.8c0.6,0.7,1.4,1,2.4,1h0.2l2,1.9v0.6H85c-0.6-0.1-1.2,0-1.8,0.4c-0.5,0.4-0.8,0.9-1,1.6c0,0.2,0,0.5,0,0.7v3.2l0.2,1h-6.1
				c0.7-5.4,1.8-10.9,3.2-16.2c1,0.2,1.8,0.1,2.6-0.1l0.7,0.9v0.8l-0.4,0.2c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1l0,0
				c0.2,0.2,0.5,0.4,0.8,0.4l0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.4,0.1,0.7,0C84.8,154.8,85,154.7,85.1,154.5 M93.2,157.1l0.5-0.1
				c0.4,0,0.9,0.2,1.2,0.5c0.2,0.3,0.4,0.7,0.5,1.1c0,0.4-0.2,0.8-0.5,1.2c-0.3,0.3-0.8,0.4-1.2,0.5c-0.2,0-0.3-0.1-0.5-0.1
				l-0.7-0.4c-0.3-0.4-0.5-0.8-0.5-1.1c0.1-0.5,0.2-0.9,0.5-1.1L93.2,157.1L93.2,157.1 M94.5,145.8l-2.3-3.2h4.7L94.5,145.8
				 M100,165.5H96v-2.7C97.3,163.8,98.6,164.7,100,165.5 M90.8,160.6l0.6,0.6c0.4,0.5,1,0.9,1.8,1v3.4H85c-0.5,0.1-0.8-0.2-0.9-0.7
				v-3.4c-0.1-0.5,0.2-0.8,0.7-0.9H90.8 M93.7,176h-7.6c-0.7,0-1.4,0.2-2.1,0.7c-0.7,0.4-1.1,1-1.3,1.9c-1,3.3-1.6,6.5-2,9.5h-5.4
				c-0.1-3-0.1-5.5-0.1-7.4c3.4-1.2,4.9-3.3,4.5-6.2h14V176 M103.5,176.1h-8v-1.5h14.1c-0.4,3,1.1,5,4.5,6.2h0l-0.1,7.3h-5.3
				c-0.4-3.4-1-6.5-1.8-9.4c-0.2-0.8-0.6-1.5-1.2-1.9C105,176.3,104.3,176.1,103.5,176.1 M89,169.2v3.6H75.5l0.3-3.6H89 M90.8,172.8
				v-3.6h7.7v3.6H90.8 M100.3,172.8v-3.5h6.2c2.3,1.1,4.7,2.1,7.2,3v0.5H100.3 M121.5,169.5l-1-1.2c-1-1.1-2.1-1.6-3.5-1.6l-0.2-2
				c1.3,0.1,2.4,0.5,3.5,1.3l1.4,1.2c2,3.1,2.7,7.3,2,12.5v0.1c0,0.4-0.1,1-0.3,1.8c-0.4-0.6-0.7-1.2-0.7-1.9
				C123.2,175,122.8,171.6,121.5,169.5 M117.4,169.8l-0.2-1.3c1.2,0.2,2.1,0.8,2.7,1.9c0.6,1,0.9,2,1,3.1c0.1,1,0.2,2,0.2,3
				c-0.1,1.4-0.2,2.4-0.2,3.1v0.2l0.4,1.5c0.2,0.8,0.6,1.4,1.2,1.8l-0.5,0.6l-0.8,0.8c-1,0.6-2,1-3,1.3c0-3.8-0.2-7.9-0.5-12.2
				l1-0.2c0.4-0.3,0.7-0.6,0.9-1c0.1-0.5,0.1-0.9-0.1-1.4c-0.2-0.4-0.6-0.7-1-0.9L117.4,169.8 M104.5,160.6c0.5-0.1,0.8,0.1,0.9,0.6
				v2.4l0,0.3l0,0.3v0.5c-2.2-1.2-4.3-2.5-6.5-4H104.5 M113.9,174.6l0.2,4.3c-0.9-0.3-1.6-0.9-2.1-1.7c-0.5-0.8-0.6-1.7-0.5-2.6
				H113.9 M76,147.7l1.5,1c-4,3.5-8.4,6.6-13,9.1l-0.5-1.1l-2.4,1.1l-0.3-0.6l2.4-1.1l-0.7-1.4l-2.4,1.1l-0.4-0.6l2.5-1.1l-0.5-1
				c4.1-2.4,7.9-5,11.4-7.9C74.2,146,75,146.9,76,147.7L76,147.7L76,147.7 M56.6,155.8v0.1l2.4,4.6l-3.6,1.6c-0.7,0.3-1.3,0.3-2,0
				c-0.7-0.3-1.2-0.8-1.5-1.5c-0.3-0.7-0.3-1.4,0-2c0.3-0.7,0.8-1.1,1.5-1.5L56.6,155.8 M77.3,177.1c-0.5,0.8-1.2,1.4-2.1,1.7
				l0.2-4.3h2.4C78,175.5,77.8,176.3,77.3,177.1 M80.3,191.8l-0.4,4.3h-4.1l-0.3-4.3H80.3 M109,191.8h4.9l-0.4,4.3h-4.1L109,191.8
				 M133,155.9L133,155.9l3.3,1.4c0.6,0.3,1.1,0.8,1.4,1.4c0.3,0.7,0.3,1.4,0,2.1c-0.3,0.7-0.7,1.2-1.4,1.4c-0.7,0.3-1.4,0.3-2,0
				l-3.6-1.6L133,155.9 M28,102.2V72.8h21.4l-8,16.1h4.4l8-16.1h5L47.2,96.1h4.5l11.6-23.4h14.3v29.5H28 M138.1,72.7h4.9l-11.5,23.4
				h4.5l11.5-23.4h13.3v29.5l-49.6,0.1V72.8l22.4-0.1l-8,16.2h4.4L138.1,72.7 M105.2,146.8c-0.2-0.1-0.4-0.1-0.6,0
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.1,0.4,0,0.7c0.3,0.9,0.5,1.8,0.5,2.9c-0.3,0-0.6,0.1-0.9,0.4c-0.3,0.3-0.4,0.6-0.4,1
				c0,0.4,0.1,0.7,0.4,1l0.4,0.2l-0.1,0.4c-0.1,0.2-0.1,0.5,0.1,0.6c0.1,0.2,0.3,0.3,0.5,0.5c0.3,0.1,0.5,0,0.7-0.1
				c0.2-0.2,0.4-0.3,0.5-0.5l0.2-0.8c0.4-0.1,0.6-0.2,0.8-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1l-0.3-0.2
				c0-1.3-0.2-2.5-0.7-3.6v0C105.7,147.1,105.5,146.9,105.2,146.8 M82,115.8l-0.1,1.8c-0.1,2.6,0.9,4.8,3,6.5
				c1.8,1.7,4.8,2.5,9.2,2.5c4.2,0,7.4-0.8,9.5-2.4c2.4-1.8,3.5-4.6,3.2-8.4l-0.1,0c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5
				H83.8c-0.5-0.1-0.9,0.1-1.2,0.5C82.3,114.9,82.1,115.3,82,115.8 M94,123.1c-3.2,0-5.5-0.5-6.8-1.6c-1.1-1-1.7-2.3-1.8-3.8h17.8
				c-0.1,1.5-0.7,2.7-1.8,3.6C99.8,122.5,97.3,123.1,94,123.1z"/>
		</g>
	</g>
</g>
    </svg>
  )
}

export default SvgComponent