import React, { useState }  from "react"
import axios from "axios";
import ContactInformation from './ContactInformation';


const Contact = () => {
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/8f8b63af-960b-467f-b78c-d45dee4f9d32",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thank you for reaching out!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
        

      <section id="three" className="contact-form wrapper style1 fade-up">
      <div className="inner">
        <h2>Get in touch</h2>
        <p>
        I love to collaborate and hear from fans, so never hesitate to get in touch.
        </p>
        <div className="split style1">
        <div>
         <div className="col-md-8 mt-5">

            <form onSubmit={handleOnSubmit}>
            <div className="fields">
          <div className="field half">
              <label htmlFor="fullname">Name
              <input type="text" name="fullname"  required="required"/></label>
            </div>
            <div className="field half">
              <label htmlFor="email">E-mail
              <input type="email" name="email" required="required"/></label>
            </div>
            <div className="field">
              <label htmlFor="message">Message
              <textarea name="message" rows="4" required="required"></textarea></label>
            </div>
          </div>
            <button type="submit" className="btn btn-primary"  disabled={serverState.submitting}>
                Submit
            </button>
            {serverState.status && (
                <div classname="alert-msg">
                <p className="alertMsg">
                {serverState.status.msg}
                </p></div>
            )}
            </form>
        </div>
      </div>  

      <ContactInformation />

        </div>
      </div>
    </section>
  

    
  
    

     
    );
  };
  
  export default Contact;