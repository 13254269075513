import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      visible: 'show',
      heading: 'Animation',
      description: 'I am primarily known for this online. My specialty involves working in Adobe Animate and After Effects to bring strange stories in my head to life.',
      iconClass: 'fas fa-video',
      to: {
        href: 'https://www.youtube.com/radiogosha',
        label: 'My Youtube Channel',
      },
    },
    {
      visible: 'show',
      heading: 'Illustration',
      description: 'From traditional sketch to using digital tools, I love to make clean, bold imagery that is a mix of modern and retro.',
      iconClass: 'fas fa-pencil-ruler',
      to: {
        href: 'https://www.deviantart.com/goshadole',
        label: 'See illustrations',
      },
    },
    {
      visible: 'show',
      heading: 'Interaction',
      description: 'This web site is an example of my front-end development skills.  I enjoy learning about new libraries and seeing the web continue to change and bring forth new ideas.',
      iconClass: 'fas fa-laptop-code',
      to: {
        href: 'https://www.linkedin.com/in/jamesfranzen',
        label: 'View LinkedIn',
      },
    },
    {
      visible: 'show',
      heading: 'Print & Merchandise',
      description: 'Working in illustration over the years has lead to me dabbling in creating various pieces of merchandise and print.  If you are interested in grabbing a print or other items related to my art, you can do so in my online shop.',
      iconClass: 'fas fa-tape',
      to: {
        href: 'https://radiogosha.storenvy.com/',
        label: 'Check out merch',
      },
    },

  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.heading} {...capability} />)}
    </>
  )
}

export default CapabilityList;
