import React from 'react';
import Scrollspy from 'react-scrollspy';
import Tab from './Tab';


const Sidebar = () => {
  const [tabs] = React.useState([
    { content: 'Welcome', href: 'intro' },
    { content: 'My latest work', href: 'one' },
    { content: 'What I do', href: 'two' },
    { content: 'Get in touch', href: 'three' },
  ]);

  return (
    <section id="sidebar">
      <div className="inner">


        <nav>        
          <Scrollspy 
            items={tabs.map(s => s.href)}
            currentClassName="active"
            offset={-300}
          >
            {tabs.map(tab => <li key={tab.href}><Tab key={tab.href} {...tab} /></li>)}
          </Scrollspy>
        </nav>
      </div>
    </section>
  );
}

export default Sidebar;
