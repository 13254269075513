import React from "react";

const ContactInformation = () =>
  <section>
    <ul className="contact">
      <li>
        <h3>Email</h3>
        <a href="mailto:james@radiogosha.com">Contact me</a>
      </li>
      <li>
        <h3>Where to find me</h3>
        <ul className="icons">
        <li>
            <a href="https://www.youtube.com/radiogosha/" className="fab fa-youtube">
              <span className="label">Youtube</span>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/goshadole" className="fab fa-twitter">
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/radiogosha" className="fab fa-facebook">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://www.deviantart.com/goshadole" className="fab fa-deviantart">
              <span className="label">Deviantart</span>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/goshadole/" className="fab fa-instagram">
              <span className="label">Instagram</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </section>

export default ContactInformation;
