import React from 'react';


const Feature = ({href, image, heading, description, to}) =>
  <section>
    <a href={href} className="image" alt="image">
      <img src={image} alt="" data-position="center center" />
    </a>
    <div className="content">
      <div className="inner">
        <h2>{heading}</h2>
        <p>{description}</p>
        
        <ul className="actions">
          <li>
            <a className="button" href={to.href}>
              {to.label}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>

export default Feature;
