import React from 'react';
import Scroll from './Scroll';

const Tab = ({content, href}) =>
    <Scroll type="id" element={href}>
      <a href={`#${href}`}>{content}</a>
    </Scroll>


export default Tab;
